@import "_globals.less";

.tx-pagesteaser {
	margin-bottom: 26px;
	
	// ******************************************************************
	// 		CUSTOM IMAGE POSITIONING
	// ******************************************************************
	.custom-image-positioning {
		overflow: hidden;
		
		> img.custom-image {
			.transform(opacity);
			display: block;
			height: 100%;
			width: 100%;
			opacity: 0;
			
			object-fit: cover;
			font-family: 'object-fit: cover;';
			
			&.custom-image-active {
				opacity: 1;
			}
		}
	}
	
	// global teaser styles
	.tx-pagesteaser-inner {
		font-size: 0;

		// IE11
		display: flex;
		flex-flow: row wrap;

		// mordern browsers
		display: grid;
		gap: 26px;
		grid-auto-rows: 1fr;
		grid-template-columns: 1fr;

		.page {
			.box-sizing(border-box);
			flex: 0 0 100%;
			font-size: 15px;
			width: 100%;
			
			.image {
				overflow: hidden;
				
				img {
					.transition(.3s);
					width: 100%;
					height: auto;
				}
			}

			&.image-zoom-on-hover {
				&:hover,
				&:focus {
					.image {
						img {
							.transform(scale(1.1));
						}
					}
				}
			}
		}
	}

	// default-layout
	&.layout-0 {
		.page-inner {
			background-color: @c-lightblue-500;
			height: 100%;
		}
		
		.image {
			margin-bottom: 13px;
			
			a,
			img {
				display: block;
			}
			
			a {
				height: 0;
				padding-bottom: 56.25%;
				position: relative;
				
				img {
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			
			+ .title {
				padding-top: 0;
			}
		}
		
		.title,
		.abstract,
		.teaser-link {
			padding-left: 20px;
			padding-right: 20px;
		}
		
		.title {
			color: @c-darkgray-500;
			font-size: 18px;
			font-weight: 500;
			line-height: 23px;
			margin: 0 0 3px 0;
			text-transform: uppercase;
			padding-top: 16px;
		}
		
		.teaser-link {
			margin-top: 20px;
			padding-bottom: 16px;
		}
	}
	
	// teaser with color-overlays
	&.layout-1 {
		.tx-pagesteaser-inner {
			grid-template-columns: 1fr 1fr; //repeat(2, 1fr);

			.page {
				flex: 0 0 50%;
			}
		}
		
		a {
			.transition(.3s);
			display: block;
			height: 0;
			padding-bottom: 56.25%;
			position: relative;
			
			.col-65 & {
				padding-bottom: 65%;
			}
		}

		.text-wrapper {
			.transform(translateY(-50%));
			display: block;
			position: absolute;
			top: 50%;
			left: 20px;
			right: 20px;
			z-index: 1;
			
			.col-65 & {
				left: 10px;
				right: 10px;
			}
		}
		
		.title,
		.categories {
			display: block;
			text-align: center;
			
		}
		
		.title {
			font-size: 32px;
			line-height: 38px;
			
			.col-65 & {
				font-size: 26px;
				line-height: 30px;
			}
		}
		
		.categories {
			margin-top: 10px;
			
			.col-65 & {
				margin-top: 5px;
			}
			
			.category {
				font-size: 20px;
				font-weight: 400;
				line-height: 26px;
				
				.col-65 & {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}

	&.layout-2,
	&.layout-3 {
		.title {
			.transition(300ms);
			color: @c-primary-1;
		}

		.page-inner {
			height: 100%;

			> a {
				.transition(300ms);

				color: #424242;
				display: block;
				font-weight: normal;
				height: 100%;
			}
		}

		img,
		.abstract,
		.text-wrapper {
			display: block;
		}

		.teasercolor-1,
		.teasercolor-2,
		.teasercolor-3,
		.teasercolor-4,
		.teasercolor-5,
		.teasercolor-6,
		.teasercolor-7,
		.teasercolor-8 {
			.image {
				margin-bottom: 0;
				margin-top: 0;
			}

			.text-wrapper {
				.transition(300ms);

				padding: 20px;
			}
		}

		&[data-columns="2"] {
			.tx-pagesteaser-inner {
				grid-template-columns: 1fr 1fr; //repeat(2, 1fr);

				.page {
					flex: 0 0 50%;
				}
			}
		}

		&[data-columns="3"] {
			.tx-pagesteaser-inner {
				grid-template-columns: 1fr 1fr 1fr; // repeat(3, 1fr);

				.page {
					flex: 0 0 33.33333333%;
				}
			}
		}
	}

	&.layout-2 {
		.image {
			margin-bottom: 15px;
		}
	}

	&.layout-3 {
		.image {
			margin-top: 15px;
		}
	}

	&.layout-1,
	&.layout-2,
	&.layout-3 {
		// Blau
		.teasercolor-1 {
			a {
				background-color: rgba(189, 215, 238, 0.7);

				&:hover,
				&:focus {
					background-color: rgba(189, 215, 238, 0.3);
				}
			}

			.title,
			.category {
				color: rgba(0, 111, 180, 1);
			}
		}

		// Grün
		.teasercolor-2 {
			a {
				background-color: rgba(197, 224, 180, 0.7);

				&:hover,
				&:focus  {
					background-color: rgba(197, 224, 180, 0.3);
				}
			}

			.title,
			.category {
				color: rgba(84, 130, 53, 1);
			}
		}

		// Orange
		.teasercolor-3 {
			a {
				background-color: rgba(239, 209, 189, 0.7);

				&:hover,
				&:focus  {
					background-color: rgba(239, 209, 189, 0.3);
				}
			}

			.title,
			.category {
				color: rgba(197, 90, 17, 1);
			}
		}

		// Gelb/Lila
		.teasercolor-4 {
			a {
				background-color: rgba(255, 242, 204, 0.7);

				&:hover,
				&:focus  {
					background-color: rgba(255, 242, 204, 0.3);
				}
			}

			.title,
			.category {
				color: rgba(112, 48, 160, 1);
			}
		}

		// Blau-2
		.teasercolor-5 {
			a {
				background-color: rgba(216, 236, 254, 1);

				&:hover,
				&:focus  {
					background-color: #b8d6f0;
				}
			}

			.title,
			.category {
				color: rgba(0, 111, 180, 1);
			}
		}

		// Blau-3
		.teasercolor-6 {
			a {
				background-color: rgba(184, 214, 240, 1);

				&:hover,
				&:focus  {
					background-color: rgba(184, 214, 240, 0.6);
				}
			}

			.title,
			.category {
				color: rgba(0, 111, 180, 1);
			}
		}

		// Blau-4
		.teasercolor-7 {
			a {
				background-color: rgba(98, 184, 232, 1);

				&:hover,
				&:focus  {
					background-color: rgba(98, 184, 232, 0.6);
				}
			}

			.title,
			.category {
				color: rgba(0, 111, 180, 1);
			}
		}

		// Hellgrau
		.teasercolor-8 {
			a {
				background-color: rgba(219, 218, 218, 1);

				&:hover,
				&:focus  {
					background-color: rgba(219, 218, 218, 0.6);
				}
			}

			.title,
			.category {
				color: rgba(0, 111, 180, 1);
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.tx-pagesteaser {
		&.layout-1 {
			.title {
				font-size: 24px;
				line-height: 28px;
			}
			
			.categories {
				.category {
					font-size: 16px;
					line-height: 20px;
				}
			}
		}
	}
}

@media screen and (max-width: 985px) {
	
}

@media screen and (max-width: 767px) {
	.tx-pagesteaser {
		margin-bottom: 40px;

		.tx-pagesteaser-inner {
			margin-left: 0;
			margin-right: 0;
			grid-template-columns: 1fr !important;
			gap: 20px;

			.page {
				display: block;
				flex: 0 0 100% !important;
				padding-left: 0;
				padding-right: 0;
				width: 100%;
			}
		}


		&.layout-1 {
			.title {
				font-size: 26px;
				line-height: 32px;
			}
			.categories {
				.category {
					font-size: 18px;
					line-height: 26px;
				}
			}
		}
	}
}

@media screen and (max-width: 479px) {
	.tx-pagesteaser {
		&.layout-1 {
			.page-inner {
				height: 100%;
			}

			a {
				&,
				.col-65 & {
					height: 100%;
					box-sizing: border-box;
					padding: 16px 20px 20px;
				}
			}
			
			.text-wrapper {
				.transform(none);
				position: static;
				
			}
		}
	}
}