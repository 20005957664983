.tx-pagesteaser {
  margin-bottom: 26px;
}
.tx-pagesteaser .custom-image-positioning {
  overflow: hidden;
}
.tx-pagesteaser .custom-image-positioning > img.custom-image {
  transform: opacity;
  -moz-transform: opacity;
  -ms-transform: opacity;
  -webkit-transform: opacity;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
.tx-pagesteaser .custom-image-positioning > img.custom-image.custom-image-active {
  opacity: 1;
}
.tx-pagesteaser .tx-pagesteaser-inner {
  font-size: 0;
  display: flex;
  flex-flow: row wrap;
  display: grid;
  gap: 26px;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
}
.tx-pagesteaser .tx-pagesteaser-inner .page {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  flex: 0 0 100%;
  font-size: 15px;
  width: 100%;
}
.tx-pagesteaser .tx-pagesteaser-inner .page .image {
  overflow: hidden;
}
.tx-pagesteaser .tx-pagesteaser-inner .page .image img {
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 100%;
  height: auto;
}
.tx-pagesteaser .tx-pagesteaser-inner .page.image-zoom-on-hover:hover .image img,
.tx-pagesteaser .tx-pagesteaser-inner .page.image-zoom-on-hover:focus .image img {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
}
.tx-pagesteaser.layout-0 .page-inner {
  background-color: #ecf5fd;
  height: 100%;
}
.tx-pagesteaser.layout-0 .image {
  margin-bottom: 13px;
}
.tx-pagesteaser.layout-0 .image a,
.tx-pagesteaser.layout-0 .image img {
  display: block;
}
.tx-pagesteaser.layout-0 .image a {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}
.tx-pagesteaser.layout-0 .image a img {
  position: absolute;
  top: 0;
  left: 0;
}
.tx-pagesteaser.layout-0 .image + .title {
  padding-top: 0;
}
.tx-pagesteaser.layout-0 .title,
.tx-pagesteaser.layout-0 .abstract,
.tx-pagesteaser.layout-0 .teaser-link {
  padding-left: 20px;
  padding-right: 20px;
}
.tx-pagesteaser.layout-0 .title {
  color: #454661;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  margin: 0 0 3px 0;
  text-transform: uppercase;
  padding-top: 16px;
}
.tx-pagesteaser.layout-0 .teaser-link {
  margin-top: 20px;
  padding-bottom: 16px;
}
.tx-pagesteaser.layout-1 .tx-pagesteaser-inner {
  grid-template-columns: 1fr 1fr;
}
.tx-pagesteaser.layout-1 .tx-pagesteaser-inner .page {
  flex: 0 0 50%;
}
.tx-pagesteaser.layout-1 a {
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  display: block;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}
.col-65 .tx-pagesteaser.layout-1 a {
  padding-bottom: 65%;
}
.tx-pagesteaser.layout-1 .text-wrapper {
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: block;
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  z-index: 1;
}
.col-65 .tx-pagesteaser.layout-1 .text-wrapper {
  left: 10px;
  right: 10px;
}
.tx-pagesteaser.layout-1 .title,
.tx-pagesteaser.layout-1 .categories {
  display: block;
  text-align: center;
}
.tx-pagesteaser.layout-1 .title {
  font-size: 32px;
  line-height: 38px;
}
.col-65 .tx-pagesteaser.layout-1 .title {
  font-size: 26px;
  line-height: 30px;
}
.tx-pagesteaser.layout-1 .categories {
  margin-top: 10px;
}
.col-65 .tx-pagesteaser.layout-1 .categories {
  margin-top: 5px;
}
.tx-pagesteaser.layout-1 .categories .category {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}
.col-65 .tx-pagesteaser.layout-1 .categories .category {
  font-size: 14px;
  line-height: 20px;
}
.tx-pagesteaser.layout-2 .title,
.tx-pagesteaser.layout-3 .title {
  transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -webkit-transition: all 300ms;
  color: #0080bc;
}
.tx-pagesteaser.layout-2 .page-inner,
.tx-pagesteaser.layout-3 .page-inner {
  height: 100%;
}
.tx-pagesteaser.layout-2 .page-inner > a,
.tx-pagesteaser.layout-3 .page-inner > a {
  transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -webkit-transition: all 300ms;
  color: #424242;
  display: block;
  font-weight: normal;
  height: 100%;
}
.tx-pagesteaser.layout-2 img,
.tx-pagesteaser.layout-3 img,
.tx-pagesteaser.layout-2 .abstract,
.tx-pagesteaser.layout-3 .abstract,
.tx-pagesteaser.layout-2 .text-wrapper,
.tx-pagesteaser.layout-3 .text-wrapper {
  display: block;
}
.tx-pagesteaser.layout-2 .teasercolor-1 .image,
.tx-pagesteaser.layout-3 .teasercolor-1 .image,
.tx-pagesteaser.layout-2 .teasercolor-2 .image,
.tx-pagesteaser.layout-3 .teasercolor-2 .image,
.tx-pagesteaser.layout-2 .teasercolor-3 .image,
.tx-pagesteaser.layout-3 .teasercolor-3 .image,
.tx-pagesteaser.layout-2 .teasercolor-4 .image,
.tx-pagesteaser.layout-3 .teasercolor-4 .image,
.tx-pagesteaser.layout-2 .teasercolor-5 .image,
.tx-pagesteaser.layout-3 .teasercolor-5 .image,
.tx-pagesteaser.layout-2 .teasercolor-6 .image,
.tx-pagesteaser.layout-3 .teasercolor-6 .image,
.tx-pagesteaser.layout-2 .teasercolor-7 .image,
.tx-pagesteaser.layout-3 .teasercolor-7 .image,
.tx-pagesteaser.layout-2 .teasercolor-8 .image,
.tx-pagesteaser.layout-3 .teasercolor-8 .image {
  margin-bottom: 0;
  margin-top: 0;
}
.tx-pagesteaser.layout-2 .teasercolor-1 .text-wrapper,
.tx-pagesteaser.layout-3 .teasercolor-1 .text-wrapper,
.tx-pagesteaser.layout-2 .teasercolor-2 .text-wrapper,
.tx-pagesteaser.layout-3 .teasercolor-2 .text-wrapper,
.tx-pagesteaser.layout-2 .teasercolor-3 .text-wrapper,
.tx-pagesteaser.layout-3 .teasercolor-3 .text-wrapper,
.tx-pagesteaser.layout-2 .teasercolor-4 .text-wrapper,
.tx-pagesteaser.layout-3 .teasercolor-4 .text-wrapper,
.tx-pagesteaser.layout-2 .teasercolor-5 .text-wrapper,
.tx-pagesteaser.layout-3 .teasercolor-5 .text-wrapper,
.tx-pagesteaser.layout-2 .teasercolor-6 .text-wrapper,
.tx-pagesteaser.layout-3 .teasercolor-6 .text-wrapper,
.tx-pagesteaser.layout-2 .teasercolor-7 .text-wrapper,
.tx-pagesteaser.layout-3 .teasercolor-7 .text-wrapper,
.tx-pagesteaser.layout-2 .teasercolor-8 .text-wrapper,
.tx-pagesteaser.layout-3 .teasercolor-8 .text-wrapper {
  transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -webkit-transition: all 300ms;
  padding: 20px;
}
.tx-pagesteaser.layout-2[data-columns="2"] .tx-pagesteaser-inner,
.tx-pagesteaser.layout-3[data-columns="2"] .tx-pagesteaser-inner {
  grid-template-columns: 1fr 1fr;
}
.tx-pagesteaser.layout-2[data-columns="2"] .tx-pagesteaser-inner .page,
.tx-pagesteaser.layout-3[data-columns="2"] .tx-pagesteaser-inner .page {
  flex: 0 0 50%;
}
.tx-pagesteaser.layout-2[data-columns="3"] .tx-pagesteaser-inner,
.tx-pagesteaser.layout-3[data-columns="3"] .tx-pagesteaser-inner {
  grid-template-columns: 1fr 1fr 1fr;
}
.tx-pagesteaser.layout-2[data-columns="3"] .tx-pagesteaser-inner .page,
.tx-pagesteaser.layout-3[data-columns="3"] .tx-pagesteaser-inner .page {
  flex: 0 0 33.33333333%;
}
.tx-pagesteaser.layout-2 .image {
  margin-bottom: 15px;
}
.tx-pagesteaser.layout-3 .image {
  margin-top: 15px;
}
.tx-pagesteaser.layout-1 .teasercolor-1 a,
.tx-pagesteaser.layout-2 .teasercolor-1 a,
.tx-pagesteaser.layout-3 .teasercolor-1 a {
  background-color: rgba(189, 215, 238, 0.7);
}
.tx-pagesteaser.layout-1 .teasercolor-1 a:hover,
.tx-pagesteaser.layout-2 .teasercolor-1 a:hover,
.tx-pagesteaser.layout-3 .teasercolor-1 a:hover,
.tx-pagesteaser.layout-1 .teasercolor-1 a:focus,
.tx-pagesteaser.layout-2 .teasercolor-1 a:focus,
.tx-pagesteaser.layout-3 .teasercolor-1 a:focus {
  background-color: rgba(189, 215, 238, 0.3);
}
.tx-pagesteaser.layout-1 .teasercolor-1 .title,
.tx-pagesteaser.layout-2 .teasercolor-1 .title,
.tx-pagesteaser.layout-3 .teasercolor-1 .title,
.tx-pagesteaser.layout-1 .teasercolor-1 .category,
.tx-pagesteaser.layout-2 .teasercolor-1 .category,
.tx-pagesteaser.layout-3 .teasercolor-1 .category {
  color: #006fb4;
}
.tx-pagesteaser.layout-1 .teasercolor-2 a,
.tx-pagesteaser.layout-2 .teasercolor-2 a,
.tx-pagesteaser.layout-3 .teasercolor-2 a {
  background-color: rgba(197, 224, 180, 0.7);
}
.tx-pagesteaser.layout-1 .teasercolor-2 a:hover,
.tx-pagesteaser.layout-2 .teasercolor-2 a:hover,
.tx-pagesteaser.layout-3 .teasercolor-2 a:hover,
.tx-pagesteaser.layout-1 .teasercolor-2 a:focus,
.tx-pagesteaser.layout-2 .teasercolor-2 a:focus,
.tx-pagesteaser.layout-3 .teasercolor-2 a:focus {
  background-color: rgba(197, 224, 180, 0.3);
}
.tx-pagesteaser.layout-1 .teasercolor-2 .title,
.tx-pagesteaser.layout-2 .teasercolor-2 .title,
.tx-pagesteaser.layout-3 .teasercolor-2 .title,
.tx-pagesteaser.layout-1 .teasercolor-2 .category,
.tx-pagesteaser.layout-2 .teasercolor-2 .category,
.tx-pagesteaser.layout-3 .teasercolor-2 .category {
  color: #548235;
}
.tx-pagesteaser.layout-1 .teasercolor-3 a,
.tx-pagesteaser.layout-2 .teasercolor-3 a,
.tx-pagesteaser.layout-3 .teasercolor-3 a {
  background-color: rgba(239, 209, 189, 0.7);
}
.tx-pagesteaser.layout-1 .teasercolor-3 a:hover,
.tx-pagesteaser.layout-2 .teasercolor-3 a:hover,
.tx-pagesteaser.layout-3 .teasercolor-3 a:hover,
.tx-pagesteaser.layout-1 .teasercolor-3 a:focus,
.tx-pagesteaser.layout-2 .teasercolor-3 a:focus,
.tx-pagesteaser.layout-3 .teasercolor-3 a:focus {
  background-color: rgba(239, 209, 189, 0.3);
}
.tx-pagesteaser.layout-1 .teasercolor-3 .title,
.tx-pagesteaser.layout-2 .teasercolor-3 .title,
.tx-pagesteaser.layout-3 .teasercolor-3 .title,
.tx-pagesteaser.layout-1 .teasercolor-3 .category,
.tx-pagesteaser.layout-2 .teasercolor-3 .category,
.tx-pagesteaser.layout-3 .teasercolor-3 .category {
  color: #c55a11;
}
.tx-pagesteaser.layout-1 .teasercolor-4 a,
.tx-pagesteaser.layout-2 .teasercolor-4 a,
.tx-pagesteaser.layout-3 .teasercolor-4 a {
  background-color: rgba(255, 242, 204, 0.7);
}
.tx-pagesteaser.layout-1 .teasercolor-4 a:hover,
.tx-pagesteaser.layout-2 .teasercolor-4 a:hover,
.tx-pagesteaser.layout-3 .teasercolor-4 a:hover,
.tx-pagesteaser.layout-1 .teasercolor-4 a:focus,
.tx-pagesteaser.layout-2 .teasercolor-4 a:focus,
.tx-pagesteaser.layout-3 .teasercolor-4 a:focus {
  background-color: rgba(255, 242, 204, 0.3);
}
.tx-pagesteaser.layout-1 .teasercolor-4 .title,
.tx-pagesteaser.layout-2 .teasercolor-4 .title,
.tx-pagesteaser.layout-3 .teasercolor-4 .title,
.tx-pagesteaser.layout-1 .teasercolor-4 .category,
.tx-pagesteaser.layout-2 .teasercolor-4 .category,
.tx-pagesteaser.layout-3 .teasercolor-4 .category {
  color: #7030a0;
}
.tx-pagesteaser.layout-1 .teasercolor-5 a,
.tx-pagesteaser.layout-2 .teasercolor-5 a,
.tx-pagesteaser.layout-3 .teasercolor-5 a {
  background-color: #d8ecfe;
}
.tx-pagesteaser.layout-1 .teasercolor-5 a:hover,
.tx-pagesteaser.layout-2 .teasercolor-5 a:hover,
.tx-pagesteaser.layout-3 .teasercolor-5 a:hover,
.tx-pagesteaser.layout-1 .teasercolor-5 a:focus,
.tx-pagesteaser.layout-2 .teasercolor-5 a:focus,
.tx-pagesteaser.layout-3 .teasercolor-5 a:focus {
  background-color: #b8d6f0;
}
.tx-pagesteaser.layout-1 .teasercolor-5 .title,
.tx-pagesteaser.layout-2 .teasercolor-5 .title,
.tx-pagesteaser.layout-3 .teasercolor-5 .title,
.tx-pagesteaser.layout-1 .teasercolor-5 .category,
.tx-pagesteaser.layout-2 .teasercolor-5 .category,
.tx-pagesteaser.layout-3 .teasercolor-5 .category {
  color: #006fb4;
}
.tx-pagesteaser.layout-1 .teasercolor-6 a,
.tx-pagesteaser.layout-2 .teasercolor-6 a,
.tx-pagesteaser.layout-3 .teasercolor-6 a {
  background-color: #b8d6f0;
}
.tx-pagesteaser.layout-1 .teasercolor-6 a:hover,
.tx-pagesteaser.layout-2 .teasercolor-6 a:hover,
.tx-pagesteaser.layout-3 .teasercolor-6 a:hover,
.tx-pagesteaser.layout-1 .teasercolor-6 a:focus,
.tx-pagesteaser.layout-2 .teasercolor-6 a:focus,
.tx-pagesteaser.layout-3 .teasercolor-6 a:focus {
  background-color: rgba(184, 214, 240, 0.6);
}
.tx-pagesteaser.layout-1 .teasercolor-6 .title,
.tx-pagesteaser.layout-2 .teasercolor-6 .title,
.tx-pagesteaser.layout-3 .teasercolor-6 .title,
.tx-pagesteaser.layout-1 .teasercolor-6 .category,
.tx-pagesteaser.layout-2 .teasercolor-6 .category,
.tx-pagesteaser.layout-3 .teasercolor-6 .category {
  color: #006fb4;
}
.tx-pagesteaser.layout-1 .teasercolor-7 a,
.tx-pagesteaser.layout-2 .teasercolor-7 a,
.tx-pagesteaser.layout-3 .teasercolor-7 a {
  background-color: #62b8e8;
}
.tx-pagesteaser.layout-1 .teasercolor-7 a:hover,
.tx-pagesteaser.layout-2 .teasercolor-7 a:hover,
.tx-pagesteaser.layout-3 .teasercolor-7 a:hover,
.tx-pagesteaser.layout-1 .teasercolor-7 a:focus,
.tx-pagesteaser.layout-2 .teasercolor-7 a:focus,
.tx-pagesteaser.layout-3 .teasercolor-7 a:focus {
  background-color: rgba(98, 184, 232, 0.6);
}
.tx-pagesteaser.layout-1 .teasercolor-7 .title,
.tx-pagesteaser.layout-2 .teasercolor-7 .title,
.tx-pagesteaser.layout-3 .teasercolor-7 .title,
.tx-pagesteaser.layout-1 .teasercolor-7 .category,
.tx-pagesteaser.layout-2 .teasercolor-7 .category,
.tx-pagesteaser.layout-3 .teasercolor-7 .category {
  color: #006fb4;
}
.tx-pagesteaser.layout-1 .teasercolor-8 a,
.tx-pagesteaser.layout-2 .teasercolor-8 a,
.tx-pagesteaser.layout-3 .teasercolor-8 a {
  background-color: #dbdada;
}
.tx-pagesteaser.layout-1 .teasercolor-8 a:hover,
.tx-pagesteaser.layout-2 .teasercolor-8 a:hover,
.tx-pagesteaser.layout-3 .teasercolor-8 a:hover,
.tx-pagesteaser.layout-1 .teasercolor-8 a:focus,
.tx-pagesteaser.layout-2 .teasercolor-8 a:focus,
.tx-pagesteaser.layout-3 .teasercolor-8 a:focus {
  background-color: rgba(219, 218, 218, 0.6);
}
.tx-pagesteaser.layout-1 .teasercolor-8 .title,
.tx-pagesteaser.layout-2 .teasercolor-8 .title,
.tx-pagesteaser.layout-3 .teasercolor-8 .title,
.tx-pagesteaser.layout-1 .teasercolor-8 .category,
.tx-pagesteaser.layout-2 .teasercolor-8 .category,
.tx-pagesteaser.layout-3 .teasercolor-8 .category {
  color: #006fb4;
}
@media screen and (max-width: 1024px) {
  .tx-pagesteaser.layout-1 .title {
    font-size: 24px;
    line-height: 28px;
  }
  .tx-pagesteaser.layout-1 .categories .category {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (max-width: 767px) {
  .tx-pagesteaser {
    margin-bottom: 40px;
  }
  .tx-pagesteaser .tx-pagesteaser-inner {
    margin-left: 0;
    margin-right: 0;
    grid-template-columns: 1fr !important;
    gap: 20px;
  }
  .tx-pagesteaser .tx-pagesteaser-inner .page {
    display: block;
    flex: 0 0 100% !important;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .tx-pagesteaser.layout-1 .title {
    font-size: 26px;
    line-height: 32px;
  }
  .tx-pagesteaser.layout-1 .categories .category {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (max-width: 479px) {
  .tx-pagesteaser.layout-1 .page-inner {
    height: 100%;
  }
  .tx-pagesteaser.layout-1 a,
  .col-65 .tx-pagesteaser.layout-1 a {
    height: 100%;
    box-sizing: border-box;
    padding: 16px 20px 20px;
  }
  .tx-pagesteaser.layout-1 .text-wrapper {
    transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    position: static;
  }
}
